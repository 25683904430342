<template>
  <section class="tables">
  
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              Liste des emplacements
            </div>
            <nav aria-label="breadcrumb" class=" float-right">
              <ol class="breadcrumb">
                <router-link to="addemplacements">
                  <li class="breadcrumb-item btn btn-primary">
                    Ajouter un emplacement
                  </li>
                </router-link>
              </ol>
            </nav>
            <div class="table-responsive">
              <input
                value=""
                class="form-control search-input"
                placeholder="Recherche par code"
                type="text"
                v-model="search"
              />
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">code</th>
                    <th scope="col">capacite</th>
                    <th scope="col">quantite_existante</th>
                    <th scope="col">capacite_vide</th>
                    <th scope="col">Action</th>
                 </tr>
                </thead>
                <tbody>
                  <tr v-for="(emplacement, index) in searchFunction" :key="index">
            
                    <td>{{ emplacement.code }}</td>
                    <td>{{ emplacement.capacite }}</td>
                    <td>{{ emplacement.quantite_existante}}</td>
                    <td>{{ emplacement.capacite_vide}}</td>
                    
                    <td>
                      <i
                        class="mdi mdi-pen icone text-success"
                        title="Modifier l'objectif"
                        @click="choose(emplacement._id)"
                      >
                      </i>
                      <i
                        class="mdi mdi-delete icone text-danger"
                        title="Supprimer l'emplacement"
                        @click.prevent="deletemplacement(emplacement._id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { HTTP } from '@/axios';
import Swal from "sweetalert2";
import Toast from "sweetalert2";
export default {
  name: "basicTables",
  data() {
    return {
      emplacements: [],
      search: "",
    };
  },

  created() {
    this.getemplacements();
  },
  computed: {
    searchFunction() {
      return this.emplacements.filter((item) => {
        return (
          item.code
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
  },
  methods: {
    getemplacements() {
      HTTP
        .get("emplacements/getemplacements")
        .then((response) => {
          this.emplacements = response.data;
        })
        .then((err) => console.log(err));
    },
    choose(id) {
      this.$router.push("editemplacements/" + id);
    },
    deletemplacement(id) {
      Swal.fire({
        title: "Êtes-vous sûrs ?",
        text: "Vous voulez supprimé l'emplacement",
        icon: "warning",
        cancelButtonText: "Annuler",
        showCancelButton: true,
        confirmButtonColor: "#3e884f",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, valider!",
      }).then((result) => {
        if (result.isConfirmed) {
          HTTP
            .delete("emplacements/deleteemplacement/" + id)
            .then((response) => {
              //this.$htmlToPaper("printNow");

              Toast.fire({
                position: "top-center",
                icon: "success",
                title: "emplacement supprimé",
              });

              this.getemplacements();
            });
        }
      });
    },
  },
};
</script>
